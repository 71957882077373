<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import { validationMixin } from "vuelidate";
import { required, integer } from 'vuelidate/lib/validators'
import errorHandler from '../../../helpers/errorHandler';
// import moment from 'moment';

/**
 * Starter component
 */
export default {
  page: {
    title: "Video Form",
  },
  mixins: [validationMixin],
  validations:{
    form:{
      video_id: {
        required,
      },
      library_id: {
        required,
      },
    }
  },
  data() {
    return {
      title: "Videos",
      items: [
        {
          text: "Work center",
          href: "/home",
        },
        {
          text: "Video form",
          href: "/sessions",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      submit: false,
      backendError: false,
      type: null,
      form:{
        video_id: null,
        library_id: null,
        stage_id: null,
        lecture_id: null,
        video_name: null,
        id: null
      },
      stageError: null,
      lectureError: null,
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  computed:{
    stages(){
      return this.$store.getters['option/years'];
    },
    lectures(){
      return this.$store.getters['option/chapters'];
    },
  },
  methods:{
    validate(){
      if(this.form.stage_id){
        this.stageError = true;
      }else{
        this.stageError = false;
      }
      if(this.form.lecture_id){
        this.lectureError = true;
      }else{
        this.lectureError = false;
      }
      return this.stageError && this.lectureError;
    },
    async handleSubmit() {
      this.submit = true;
      await this.$v.$touch();
      if(this.validate() &&!this.$v.$anyError ){
        // requests for the create
        this.$store.dispatch("video/addVideo",this.form).then(
           ()=>{
            this.$router.push('/videos');
          }
        ).catch((err)=>{
          errorHandler.methods.errorMessage(err);
        });
      }
    },
    async handleUpdate(){
      this.submit = true;
      await this.$v.$touch();
      if(this.validate() &&!this.$v.$anyError){
        // requests for the create
        this.$store.dispatch("video/editVideo",this.form).then(
           ()=>{
            this.$router.go(-1);
          }
        ).catch((err)=>{
          errorHandler.methods.errorMessage(err);
        });
      }
    },
    async clearSelectError5(){
      this.stageError = null;
      this.form.lecture_id = '';
      await this.$store.dispatch('option/clearChaptersOption');
      await this.$store.dispatch('option/getYearChapterOption', this.form.stage_id).then(()=>{}).catch( err =>{
      errorHandler.methods.errorMessage(err);
    });
    },
    clearSelectError6(){
      this.lectureError = null;
    },

  },
  async created(){
    await this.$store.dispatch('option/getYearOption').then(()=>{}).catch( err =>{
      errorHandler.methods.errorMessage(err);
    });
    if(this.$route.params.id){
        this.type = 'edit';
        await this.$store.dispatch('option/clearChaptersOption');
        await this.$store.dispatch('video/getVideoInfo',this.$route.params.id).then( async token =>{
            this.form.stage_id = token.chapter.educational_year.id;
            await this.$store.dispatch('option/getYearChapterOption', this.form.stage_id).then(()=>{}).catch( err =>{
              errorHandler.methods.errorMessage(err);
            });
            this.form.lecture_id = token.chapter.id;
            this.form.video_id = token.video_id;
            this.form.library_id = token.library_id;
            this.form.video_name = token.video_name;
            this.form.id = this.$route.params.id;
        }).catch( err =>{
            errorHandler.methods.errorMessage(err);
        })
    }else{
        this.type = 'create';
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2 v-if="type == 'create'">Create Video</h2>
                    <h2 v-if="type == 'edit'">Edit Video</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-md-6" v-if="form.video_name">
                  <b-form-group
                      id="input-group-2"
                      label="Name:"
                      label-for="input-D"
                  >
                      <input v-if="(type == 'edit' && (form.video_name != null))" v-model="form.video_name" type="text" name="name" class="form-control" placeholder="Enter video name" />
                  </b-form-group>
                  </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Video ID:"
                        label-for="input-D"
                    >
                        <input v-if="type == 'create' || (type == 'edit' && (form.video_id != null))" v-model="form.video_id" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.video_id.$error }" placeholder="Enter video ID" />
                        <div v-if="submit && $v.form.video_id.$error" class="invalid-feedback">
                            <span v-if="!$v.form.video_id.required">This field is required.</span>
                            
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Library ID:"
                        label-for="input-D"
                    >
                        <input v-if="type == 'create' || (type == 'edit' && form.library_id != null)" v-model="form.library_id" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.library_id.$error }" placeholder="Enter video library ID" />
                        <div v-if="submit && $v.form.library_id.$error" class="invalid-feedback">
                            <span v-if="!$v.form.library_id.required">This field is required.</span>
                            
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6" >
                      <b-form-group label="Stage:" label-for="input-C" >
                        <b-form-select v-if="type == 'create' || (type == 'edit' && form.stage_id != null)"  v-model="form.stage_id" :options="this.stages" :state="stageError" @change="clearSelectError5"></b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-md-6" v-if="form.stage_id">
                      <b-form-group label="Lecture:" label-for="input-C" >
                        <b-form-select v-if="type == 'create' || (type == 'edit' && form.lecture_id != null)" v-model="form.lecture_id" :options="this.lectures" :state="lectureError" @change="clearSelectError6"></b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                </div>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit" v-if="type == 'create'">Submit</button>
                <button class="btn btn-outline-success" type="submit" @click="handleUpdate" v-if="type == 'edit'">Update</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>